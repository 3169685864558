<template>
  <div v-if="!submitted">
    <Card>
      <template v-slot:header> Новый курс </template>
      <div class="row">
        <div class="col-12">
          <Input
            v-model="course.name"
            placeholder="Курс #1"
            label="Название курса"
            required
          ></Input>
        </div>
        <div class="col-sm-12">
          <Input
            v-model="course.init_message"
            tag="textarea"
            rows="6"
            placeholder="О курсе"
          >
            <template v-slot:label>
              <label>Первое сообщение</label>
            </template>
          </Input>
        </div>

        <div class="col-sm-12">
          <Input
            v-model="course.url_trailer"
            placeholder="ссылка на трейлер курса"
          >
            <template v-slot:label>
              <label>Трейлер курса</label>
            </template>
          </Input>
        </div>

        <div class="col-sm-12">
          <Input
            v-model="course.url_coursera"
            placeholder="Cсылка на Coursera"
          >
            <template v-slot:label>
              <label>Ссылка курса на Coursera</label>
            </template>
          </Input>
        </div>
      </div>

      <div>
        <div class="input-label">
          <label>Приветственное изображение курса</label>
        </div>
        <Dropzone
          v-slot:default="{ files, removeFileByIndex, active, browse }"
          ref="dropzone"
          @change="course_images = $event"
        ></Dropzone>
      </div>

      <div v-if="course_images && course_images.length > 0">
        <div class="mt-4">Добавленные файлы:</div>
        <div v-for="file in course_images">
          <div class="input-label">
            <label>{{ file.name }}</label>
          </div>
          <img style="max-width: 100px;" :src="file.src"/>
        </div>
      </div>

      <div class="input-group pt-5">
        <Button @click="saveCourse" type="outline-light-bg-success"
          >Создать</Button
        >
      </div>
    </Card>
  </div>
  <div v-else>
    <h4>Курс создан!</h4>
    <button class="btn btn-success" @click="newCourse">Добавить еще один</button>
  </div>
</template>

<script>
import CourseService from "../../services/CourseService";
import swal from "sweetalert";
import FormData from 'form-data';

var defaultData = {
  id: "",
  name: "",
  url_trailer: "",
  url_coursera: "",
  init_message: "О курсе:\n\nЦель курса:\n\nЦелевая аудитория:",
  images: [],
};

export default {
  name: "Forms",

  data() {
    return {
      course: {
        id: defaultData.id,
        name: defaultData.name,
        init_message: defaultData.init_message,
        url_trailer: defaultData.url_trailer,
        url_coursera: defaultData.url_coursera
      },
      course_images: defaultData.images,
      submitted: false
    };
  },

  methods: {
    saveCourse() {
      if (!this.course.name) {
        swal("Ошибка!", "Поле «Название курса» не заполнено.", "warning");
        return;
      }
      if (!this.course.init_message) {
        swal("Ошибка!", "Поле «Первое сообщение» не заполнено.", "warning");
        return;
      }
      if (!this.course_images || this.course_images.length === 0) {
        swal("Ошибка!", "Поле «Приветственное изображение курса» не заполнено.", "warning");
        return;
      }

      var form = new FormData()
      form.append('name', this.course.name);
      form.append('init_message', this.course.init_message);
      form.append('url_trailer', this.course.url_trailer);
      form.append('url_coursera', this.course.url_coursera);
      form.append('image', this.course_images[0]);

      CourseService.create(form)
        .then(response => {
          this.course.id = response.data._id;
          // console.log(response.data);
          this.submitted = true;
        })
        .catch(e => {
          swal("Ошибка!", "Что то пошло не так", "error");
          console.log(e.toJSON());
        });
    },

    async newCourse() {
      this.submitted = false;
      this.course.id = defaultData.id;
      this.course.name = defaultData.name;
      this.course.init_message = defaultData.init_message;
      this.course.url_trailer = defaultData.url_trailer;
      this.course_images = defaultData.images;
      this.course.url_coursera = defaultData.url_coursera;
      await this.$nextTick();
    },

    getBase64 (file, callback) {
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result));
      reader.readAsDataURL(file);
    }
  },

  watch: {
    course_images: function(images){
      let vm = this;
      images.forEach(image => {
        if (image.src) {
          // console.log('return');
          // console.log(this.course_images);
          return;
        }

        this.getBase64(image, function(base64Data){
            image.src = base64Data;
            // console.log(image);
            vm.$forceUpdate();
        });
      });
    }
  }
};
</script>

<style scoped>
</style>